/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
	display:inline;
}
figcaption {
	font-family: sans-serif; font-size: 0.75rem; margin-top: -1.5rem; margin-bottom: 1.5rem;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"syntax",
    "starsnonscss"
;
